import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import '../App.css';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import $ from 'jquery';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import battle from '../assets/battle.png';
import secretGif from '../assets/logo-up.png';
import bell from '../assets/bell.png';
import telegram from '../assets/telegram.png';
import logoUp from '../assets/logo-up.png';

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 3500);
});


const opensea = () => {
	window.open("#");
}

const home = () => {
	window.open("https://frogchain.io/");
}
const mintSite = () => {
	window.open("https://mint.frogchain.io");

}

const stakeSite = () => {
	window.open("https://nftstake.frogchain.io");

}

const gameSite = () => {
	window.open("https://battlefield.frogchain.io");

};

const mm = () => {
	window.open("https://metamask.app.link/dapp/battlefrog3.pages.dev/");

};

const tg = () => {
	window.open("#");
}

const tweet = () => {
	window.open("#");
}

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let ingvalue;
let myTokenIDs = [];
let x = '';
let myTokenURLs = '';
let myTokenIDsForm = [];
let z = 0;
let myStakeNFT = '';
let isApproved = false;
let getMyStakedTokens = [];
let getStakedDetailsWindow = 0;
let myStakedTokenCount;
let getRewards = [];
let q = -1;
let getRewardsRounded;
let elementArray = [];
let rewardsTime;
let rewardDetails;
let getMyOwnNFTDetailsWindow = 0;
let myTokenAmount;
let pendingRewards = 0;
let allRewardsRealeasedByOwners = [];
let countOfOverallStakers;
let stakers;
let rewardsAmount;
let rewardsRate;
let AquaFrogsCount;
let gameStartTime;
let FireFrogsCount;
let gameDuration;
let stakingEligibilityTime;
let additionalTime;
let gameIndex;
let stakeFrog_FireFrogs;
let allowance;
let individualTotalStakingCount;
let endTime;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses =

	[

	];

let whitelistAddresses2 = [];

let ABIStaking = [
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_tokenIDs",
				"type": "uint256[]"
			}
		],
		"name": "batchStakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_tokenIDs",
				"type": "uint256[]"
			}
		],
		"name": "batchUnstakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimWinningRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"name": "onERC721Received",
		"outputs": [
			{
				"internalType": "bytes4",
				"name": "",
				"type": "bytes4"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_additionalTime",
				"type": "uint256"
			}
		],
		"name": "setAdditionalTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimalNumber",
				"type": "uint256"
			}
		],
		"name": "setDecimalNumber",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_gameDuration",
				"type": "uint256"
			}
		],
		"name": "setGameDuration",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_additionalTime",
				"type": "uint256"
			}
		],
		"name": "setGameEnd",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_gameIndex",
				"type": "uint256"
			}
		],
		"name": "setGameIndex",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "setGameStart",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_gameStartTime",
				"type": "uint256"
			}
		],
		"name": "setGameStartTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_nftContract",
				"type": "address"
			}
		],
		"name": "setNFTContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardsAmount",
				"type": "uint256"
			}
		],
		"name": "setRewardsAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardsCircle",
				"type": "uint256"
			}
		],
		"name": "setRewardsCircle",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardsRate",
				"type": "uint256"
			}
		],
		"name": "setRewardsRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_stakingEligibilityTime",
				"type": "uint256"
			}
		],
		"name": "setStakingEligibilityTime",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_winnerPrizeRate",
				"type": "uint256"
			}
		],
		"name": "setWinnerPrizeRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "stakeFrog_AquaFrogs",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "stakeFrog_FireFrogs",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenID",
				"type": "uint256"
			}
		],
		"name": "stakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenID",
				"type": "uint256"
			}
		],
		"name": "unstakeNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "additionalTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "alreadyStakedNFT",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "AquaFrogsCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "countofMyStakedTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "countOfOverallStakers",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalNumber",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "FireFrogsCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "gameDuration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "gameIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "gameStartTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "individualStakingCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "individualStakingIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "individualStakingStartTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "isAquaFrog",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "isFireFrog",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "myGameIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nft",
		"outputs": [
			{
				"internalType": "contract IERC721",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "nftOwner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardsAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardsCircle",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardsRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "stakedTokenOwner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "stakedTokens",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "stakers",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "stakingEligibilityTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "tokenStakedDuration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "tokenStakedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "totalRewardReleased",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winner",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "winnerPrizeRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let addressStaking = "0xC9bE9ca604c8a5284aDadc47475b6fE2D29bfE94";

let ABIToken = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let addressToken = "0x4E01b576d3c0bb5A3655AFc883F6AdcF6C6bcD45";

let ABINFT = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "claim",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimals",
				"type": "uint256"
			}
		],
		"name": "setDecimals",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minimumNFTsHolding",
				"type": "uint256"
			}
		],
		"name": "setMinimumNFTsHolding",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenAmount",
				"type": "uint256"
			}
		],
		"name": "setTokenAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_claimOpen",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_switchOption1",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_switchOption2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_token_Contract",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "allocated",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "allocations",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "claimOpen",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minimumNFTsHolding",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "share",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "switchOption1",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "switchOption2",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "withdrawn",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let addressNFT = "0x115c176b93D58c28b4b5933EA1055bB660E00417";

let contractStaking;
let contractToken;
let contractNFT;
let stakeFrog_AquaFrogs
let alreadyStakedNFT = false;

class Stake extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_ingValue: '',
		_myTokenIDs: [],
		_obj: 'pic',
		_myTokenURLs: '',
		_num: '',
		_value: '',
		_myStakeNFT: '',
		_isApproved: false,
		_getMyStakedTokens: [],
		_getStakedDetailsWindow: 0,
		_myStakedTokenCount: '',
		_getRewards: [],
		_rewardDetails: [],
		_elementArray: [],
		_getMyOwnNFTDetailsWindow: 0,
		_myTokenAmount: '',
		_pendingRewards: 0,
		_newPendingRewards: 0,
		_allRewardsRealeasedByOwners: [],
		_countOfOverallStakers: '',
		_stakers: '',
		_navbarOpen: 0,
		_stakeFrog_AquaFrogs: 0,
		_rewardsAmount: '',
		_rewardsRate: '',
		_AquaFrogsCount: '',
		_gameStartTime: '',
		_FireFrogsCount: '',
		_gameDuration: '',
		_stakingEligibilityTime: '',
		_additionalTime: '',
		_gameIndex: '',
		_stakeFrog_FireFrogsTokens: '',
		_approved: '',
		_individualTotalStakingCount: '',
		_alreadyStakedNFT: false,
		_endTime: ''
	}

	async componentDidMount() {

		if (localStorage?.getItem('isWalletConnected') === 'true') {
			const providerOptions = {
				walletconnect: {
					package: WalletConnectProvider, // required
					options: {
						infuraId: "bf933c3446b3464c988114813a1360ac" // required
					}
				}
			};

			const web3Modal = new Web3Modal({
				network: "mainnet", // optional
				cacheProvider: true, // optional
				providerOptions // required
			});

			const provider = await web3Modal.connect();

			//  Enable session (triggers QR Code modal)
			await provider.enable();

			const web3 = new Web3(provider);
			console.log("provider : " + provider);
			// Subscribe to accounts change
			provider.on("accountsChanged", (accounts) => {
				console.log(accounts);
			});

			// Subscribe to chainId change
			provider.on("chainChanged", (chainId) => {
				console.log(chainId);
			});

			// Subscribe to provider connection
			provider.on("connect", async (info) => {
				console.log(info);
				
			});

			// Subscribe to provider disconnection
			provider.on("disconnect", (error) => {
				console.log(error);
			});

			//  if wallet is connected
			if (web3Modal.cachedProvider) {
				// connected now you can get accounts
				console.log("provider :" + web3Modal.cachedProvider);
				const accounts = await web3.eth.getAccounts();

				account = accounts[0];
				this.setState({ walletAddress: account });

				contractNFT = new web3.eth.Contract(ABINFT, addressNFT);
				console.log("contractNFT :" + contractNFT);

				contractToken = new web3.eth.Contract(ABIToken, addressToken);
				console.log("contractToken :" + contractToken);


				contractStaking = new web3.eth.Contract(ABIStaking, addressStaking);
				console.log("contractStaking :" + contractStaking);

				if (provider) {


					(async () => {


						if (provider != "walletconnect") {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							const chainId = 84531;

							if (window.ethereum.networkVersion !== chainId) {
								try {
									await window.ethereum.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'Polygon Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://polygon-rpc.com/'],*/

													/*chainName: 'Goerli  Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],*/

													chainName: 'Base Goerli',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://goerli.base.org'],

													/*chainName: 'Sepolia test network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Sepolia test network', decimals: 18, symbol: 'SepoliaETH' },
													rpcUrls: ['https://sepolia.infura.io/v3/'],*/
												},
											],
										});
									}
								}
							}

							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}


								allowance = await contractToken.methods.allowance(account, addressStaking).call();
								console.log("allowance:" + allowance);

								rewardsAmount = await contractStaking.methods.rewardsAmount().call();
								this.setState({ _rewardsAmount: rewardsAmount });
								console.log("rewardsAmount:" + rewardsAmount);

								rewardsRate = await contractStaking.methods.rewardsRate().call();
								this.setState({ _rewardsRate: rewardsRate });
								console.log("rewardsRate:" + rewardsRate);

								countOfOverallStakers = await contractStaking.methods.countOfOverallStakers().call();
								this.setState({ _countOfOverallStakers: countOfOverallStakers });
								console.log("countOfOverallStakers:" + countOfOverallStakers);

								gameIndex = await contractStaking.methods.gameIndex().call();
								this.setState({ _gameIndex: gameIndex });
								console.log("gameIndex:" + gameIndex);

								AquaFrogsCount = await contractStaking.methods.AquaFrogsCount(gameIndex).call();
								this.setState({ _AquaFrogsCount: AquaFrogsCount });
								console.log("AquaFrogsCount:" + AquaFrogsCount);

								FireFrogsCount = await contractStaking.methods.FireFrogsCount(gameIndex).call();
								this.setState({ _FireFrogsCount: FireFrogsCount });
								console.log("FireFrogsCount:" + FireFrogsCount);

								gameStartTime = await contractStaking.methods.gameStartTime().call();
								this.setState({ _gameStartTime: gameStartTime });
								console.log("gameStartTime:" + gameStartTime);

								gameDuration = await contractStaking.methods.gameDuration().call();
								this.setState({ _gameDuration: gameDuration });
								console.log("gameDuration:" + gameDuration);

								stakingEligibilityTime = await contractStaking.methods.stakingEligibilityTime().call();
								this.setState({ _stakingEligibilityTime: stakingEligibilityTime });
								console.log("stakingEligibilityTime:" + stakingEligibilityTime);

								additionalTime = await contractStaking.methods.additionalTime().call();
								this.setState({ _additionalTime: additionalTime });
								console.log("additionalTime:" + additionalTime);

								endTime = gameStartTime + gameDuration + additionalTime;
								this.setState({ _endTime: endTime });

								totalSupplyNFT = await contractNFT.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
								this.setState({ _isApproved: isApproved });
								console.log(isApproved);

								publicSale = await contractNFT.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
								console.log("myStakedTokenCount : " + myStakedTokenCount);

								myTokenAmount = await contractToken.methods.balanceOf(account).call();
								this.setState({ _myTokenAmount: myTokenAmount });

								alreadyStakedNFT = await contractStaking.methods.alreadyStakedNFT(account).call();
								if (alreadyStakedNFT) {
									console.log("alreadyStakedNFT : " + alreadyStakedNFT);
									this.setState({ _alreadyStakedNFT: true });
									console.log("_alreadyStakedNFT : " + this.state._alreadyStakedNFT);
								}

								if (allowance > 0) {
									this.setState({ _approved: true });
									console.log("approvedStormsChosen : " + this.state._approved);

								} else {
									this.setState({ _approved: false });
									console.log("approvedStormsChosen : " + this.state._approved);
								}

								let a;
								for (a = 0; a < myStakedTokenCount; a++) {
									getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
									this.setState({ _getMyStakedTokens: getMyStakedTokens });
									console.log("getMyStakedTokens : " + getMyStakedTokens);

									let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
									let timeDuration = Date.now() - (tokenTime * 1000);

									if (timeDuration >= 0) {

										getRewards[a] = (Date.now() - (tokenTime * 1000)) * 10 / 86400000;

									} else {
										getRewards[a] = 0;
									}

									elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(4)];
									this.setState({ _elementArray: elementArray });

									this.setState({ _getRewards: getRewards });
									console.log("tokenTime :" + tokenTime * 1000);
									console.log("tokenTimeDuration :" + timeDuration);
									console.log("getRewards : " + getRewards[a].toFixed(2));

									this.setState({ _getMyStakedTokens: getMyStakedTokens });
									console.log("getMyStakedTokens : " + getMyStakedTokens);
									console.log("elementArray[a] : " + elementArray[a]);
									console.log("elementArray[a][0] : " + elementArray[a][0]);

								}


								for (let q = 0; q < getRewards.length; q++) {
									pendingRewards = pendingRewards * 1 + getRewards[q] * 1;
									this.setState({ _newPendingRewards: pendingRewards * 1 });
									console.log("_newPendingRewards :" + this.state._newPendingRewards);

								}


								for (let y = 0; y < this.state.myNFTWallet; y++) {
									myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
									this.setState({ _myTokenIDs: myTokenIDs });

								}

								console.log("myTokenIDs : " + myTokenIDs[0]);
								console.log("myTokenIDs : " + this.state._myTokenIDs);

								owner = await contractNFT.methods.owner().call();
								console.log("Owner" + owner);


							} catch (err) {
								console.log("err: " + err);

							}
						} else {


							const chainId = 84531;

							if (WalletConnectProvider.networkVersion !== chainId) {
								try {
									await WalletConnectProvider.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													chainName: 'Base Goerli',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://goerli.base.org'],

													/*chainName: 'Sepolia test network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Sepolia test network', decimals: 18, symbol: 'SepoliaETH' },
													rpcUrls: ['https://sepolia.infura.io/v3/'],*/

													/*chainName: 'Polygon Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://polygon-rpc.com/'],*/

													/*chainName: 'Goerli  Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],*/
												},
											],
										});
									}
								}
							}




							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								allowance = await contractToken.methods.allowance(account, addressStaking).call();
								console.log("allowance:" + allowance);

								rewardsAmount = await contractStaking.methods.rewardsAmount().call();
								this.setState({ _rewardsAmount: rewardsAmount });
								console.log("rewardsAmount:" + rewardsAmount);

								rewardsRate = await contractStaking.methods.rewardsRate().call();
								this.setState({ _rewardsRate: rewardsRate });
								console.log("rewardsRate:" + rewardsRate);

								countOfOverallStakers = await contractStaking.methods.countOfOverallStakers().call();
								this.setState({ _countOfOverallStakers: countOfOverallStakers });
								console.log("countOfOverallStakers:" + countOfOverallStakers);

								gameIndex = await contractStaking.methods.gameIndex().call();
								this.setState({ _gameIndex: gameIndex });
								console.log("gameIndex:" + gameIndex);

								AquaFrogsCount = await contractStaking.methods.AquaFrogsCount(gameIndex).call();
								this.setState({ _AquaFrogsCount: AquaFrogsCount });
								console.log("AquaFrogsCount:" + AquaFrogsCount);

								FireFrogsCount = await contractStaking.methods.FireFrogsCount(gameIndex).call();
								this.setState({ _FireFrogsCount: FireFrogsCount });
								console.log("FireFrogsCount:" + FireFrogsCount);

								gameStartTime = await contractStaking.methods.gameStartTime().call();
								this.setState({ _gameStartTime: gameStartTime });
								console.log("gameStartTime:" + gameStartTime);

								gameDuration = await contractStaking.methods.gameDuration().call();
								this.setState({ _gameDuration: gameDuration });
								console.log("gameDuration:" + gameDuration);

								stakingEligibilityTime = await contractStaking.methods.stakingEligibilityTime().call();
								this.setState({ _stakingEligibilityTime: stakingEligibilityTime });
								console.log("stakingEligibilityTime:" + stakingEligibilityTime);

								additionalTime = await contractStaking.methods.additionalTime().call();
								this.setState({ _additionalTime: additionalTime });
								console.log("additionalTime:" + additionalTime);

								endTime = gameStartTime + gameDuration + additionalTime;
								this.setState({ _endTime: endTime });


								totalSupplyNFT = await contractNFT.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });

								console.log("Total Supply:" + totalSupplyNFT);


								isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
								this.setState({ _isApproved: isApproved });
								console.log(isApproved);

								publicSale = await contractNFT.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
								console.log("myStakedTokenCount : " + myStakedTokenCount);

								myTokenAmount = await contractToken.methods.balanceOf(account).call();
								this.setState({ _myTokenAmount: myTokenAmount });

								alreadyStakedNFT = await contractStaking.methods.alreadyStakedNFT(account).call();
								if (alreadyStakedNFT) {
									console.log("alreadyStakedNFT : " + alreadyStakedNFT);
									this.setState({ _alreadyStakedNFT: true });
									console.log("_alreadyStakedNFT : " + this.state._alreadyStakedNFT);
								}

								if (allowance > 0) {
									this.setState({ _approved: true });
									console.log("approvedStormsChosen : " + this.state._approved);

								} else {
									this.setState({ _approved: false });
									console.log("approvedStormsChosen : " + this.state._approved);
								}

								let a;
								for (a = 0; a < myStakedTokenCount; a++) {
									getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
									this.setState({ _getMyStakedTokens: getMyStakedTokens });
									console.log("getMyStakedTokens : " + getMyStakedTokens);

									let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
									let timeDuration = Date.now() - (tokenTime * 1000);

									if (timeDuration >= 0) {

										getRewards[a] = (Date.now() - (tokenTime * 1000)) * 10 / 86400000;

									} else {
										getRewards[a] = 0;
									}

									elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(4)];
									this.setState({ _elementArray: elementArray });

									this.setState({ _getRewards: getRewards });
									console.log("tokenTime :" + tokenTime * 1000);
									console.log("tokenTimeDuration :" + timeDuration);
									console.log("getRewards : " + getRewards[a].toFixed(2));

									this.setState({ _getMyStakedTokens: getMyStakedTokens });
									console.log("getMyStakedTokens : " + getMyStakedTokens);
									console.log("elementArray[a] : " + elementArray[a]);
									console.log("elementArray[a][0] : " + elementArray[a][0]);

								}


								for (let q = 0; q < getRewards.length; q++) {
									pendingRewards = pendingRewards * 1 + getRewards[q] * 1;
									this.setState({ _newPendingRewards: pendingRewards * 1 });
									console.log("_newPendingRewards :" + this.state._newPendingRewards);

								}



								for (let y = 0; y < this.state.myNFTWallet; y++) {
									myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
									this.setState({ _myTokenIDs: myTokenIDs })

								}


								console.log("myTokenIDs : " + myTokenIDs[0]);
								console.log("myTokenIDs : " + this.state._myTokenIDs);

								owner = await contractNFT.methods.owner().call();
								console.log("Owner" + owner);


							} catch (err) {
								console.log("err: " + err);

							}
						}
					})();

					//.....................................................................//

					// Legacy providers may only have ethereum.sendAsync
					const chainId = await provider.request({
						method: 'eth_chainId'
					})

				} else {

					// if the provider is not detected, detectEthereumProvider resolves to null
					console.error('Please install a Valid Wallet');
					alert('A valid provider could not be found!');

				}
			}
		} else {

			console.log("NOT CONNECTED");

		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contractNFT.methods.owner().call();


			//	if (account != owner) {


			try {


				console.log("minAmount:" + mintAmount);
				console.log("valueOfNFTs:" + valueOfNFTs);

				//contract = new window.web3.eth.Contract(ABI,'0x802ec13e2e3afe078bc15035f80f0f82bea9bc6c');

				this.setState({ statusError: false, statusLoading: true });
				await contractNFT.methods.mint(mintAmount * 1).send({ /*gasLimit: 390000,*//*gasLimit: 285000, maxPriorityFeePerGas: "196000000000", maxFeePerGas: "202000000000", */gasLimit: 390000, from: account, value: this.state.totalValue * 0 });
				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.mintAmount);

			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}

		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		//  if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contractNFT = new web3.eth.Contract(ABINFT, addressNFT);
			console.log("contractNFT :" + contractNFT);

			contractToken = new web3.eth.Contract(ABIToken, addressToken);
			console.log("contractToken :" + contractToken);


			contractStaking = new web3.eth.Contract(ABIStaking, addressStaking);
			console.log("contractStaking :" + contractStaking);

			if (provider) {


				(async () => {


					if (provider != "walletconnect") {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						const chainId = 84531;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],*/

												/*chainName: 'Goerli  Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												chainName: 'Base Goerli',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://goerli.base.org'],

												/*chainName: 'Sepolia test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Sepolia test network', decimals: 18, symbol: 'SepoliaETH' },
												rpcUrls: ['https://sepolia.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}

						try {

							allowance = await contractToken.methods.allowance(account, addressStaking).call();
							console.log("allowance:" + allowance);

							rewardsAmount = await contractStaking.methods.rewardsAmount().call();
							this.setState({ _rewardsAmount: rewardsAmount });
							console.log("rewardsAmount:" + rewardsAmount);

							rewardsRate = await contractStaking.methods.rewardsRate().call();
							this.setState({ _rewardsRate: rewardsRate });
							console.log("rewardsRate:" + rewardsRate);

							countOfOverallStakers = await contractStaking.methods.countOfOverallStakers().call();
							this.setState({ _countOfOverallStakers: countOfOverallStakers });
							console.log("countOfOverallStakers:" + countOfOverallStakers);

							AquaFrogsCount = await contractStaking.methods.AquaFrogsCount(gameIndex).call();
							this.setState({ _AquaFrogsCount: AquaFrogsCount });
							console.log("AquaFrogsCount:" + AquaFrogsCount);

							FireFrogsCount = await contractStaking.methods.FireFrogsCount(gameIndex).call();
							this.setState({ _FireFrogsCount: FireFrogsCount });
							console.log("FireFrogsCount:" + FireFrogsCount);

							gameStartTime = await contractStaking.methods.gameStartTime().call();
							this.setState({ _gameStartTime: gameStartTime });
							console.log("gameStartTime:" + gameStartTime);

							gameDuration = await contractStaking.methods.gameDuration().call();
							this.setState({ _gameDuration: gameDuration });
							console.log("gameDuration:" + gameDuration);

							stakingEligibilityTime = await contractStaking.methods.stakingEligibilityTime().call();
							this.setState({ _stakingEligibilityTime: stakingEligibilityTime });
							console.log("stakingEligibilityTime:" + stakingEligibilityTime);

							additionalTime = await contractStaking.methods.additionalTime().call();
							this.setState({ _additionalTime: additionalTime });
							console.log("additionalTime:" + additionalTime);

							endTime = gameStartTime + gameDuration + additionalTime;
							this.setState({ _endTime: endTime });

							gameIndex = await contractStaking.methods.gameIndex().call();
							this.setState({ _gameIndex: gameIndex });
							console.log("gameIndex:" + gameIndex);

							totalSupplyNFT = await contractNFT.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
							this.setState({ _isApproved: isApproved });
							console.log(isApproved);

							publicSale = await contractNFT.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
							console.log("myStakedTokenCount : " + myStakedTokenCount);

							myTokenAmount = await contractToken.methods.balanceOf(account).call();
							this.setState({ _myTokenAmount: myTokenAmount });

							alreadyStakedNFT = await contractStaking.methods.alreadyStakedNFT(account).call();
							if (alreadyStakedNFT) {
								console.log("alreadyStakedNFT : " + alreadyStakedNFT);
								this.setState({ _alreadyStakedNFT: true });
								console.log("_alreadyStakedNFT : " + this.state._alreadyStakedNFT);
							}

							if (allowance > 0) {
								this.setState({ _approved: true });
								console.log("approvedStormsChosen : " + this.state._approved);

							} else {
								this.setState({ _approved: false });
								console.log("approvedStormsChosen : " + this.state._approved);
							}

							let a;
							for (a = 0; a < myStakedTokenCount; a++) {
								getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
								this.setState({ _getMyStakedTokens: getMyStakedTokens });
								console.log("getMyStakedTokens : " + getMyStakedTokens);

								let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
								let timeDuration = Date.now() - (tokenTime * 1000);

								if (timeDuration >= 0) {

									getRewards[a] = (Date.now() - (tokenTime * 1000)) * 10 / 86400000;

								} else {
									getRewards[a] = 0;
								}

								elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(4)];
								this.setState({ _elementArray: elementArray });

								this.setState({ _getRewards: getRewards });
								console.log("tokenTime :" + tokenTime * 1000);
								console.log("tokenTimeDuration :" + timeDuration);
								console.log("getRewards : " + getRewards[a].toFixed(2));

								this.setState({ _getMyStakedTokens: getMyStakedTokens });
								console.log("getMyStakedTokens : " + getMyStakedTokens);
								console.log("elementArray[a] : " + elementArray[a]);
								console.log("elementArray[a][0] : " + elementArray[a][0]);

							}


							for (let q = 0; q < getRewards.length; q++) {
								pendingRewards = pendingRewards * 1 + getRewards[q] * 1;
								this.setState({ _newPendingRewards: pendingRewards * 1 });
								console.log("_newPendingRewards :" + this.state._newPendingRewards);

							}


							for (let y = 0; y < this.state.myNFTWallet; y++) {
								myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs });

							}

							console.log("myTokenIDs : " + myTokenIDs[0]);
							console.log("myTokenIDs : " + this.state._myTokenIDs);

							owner = await contractNFT.methods.owner().call();
							console.log("Owner" + owner);


						} catch (err) {
							console.log("err: " + err);

						}
					} else {


						const chainId = 84531;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Base Goerli',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://goerli.base.org'],

												/*chainName: 'Sepolia test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Sepolia test network', decimals: 18, symbol: 'SepoliaETH' },
												rpcUrls: ['https://sepolia.infura.io/v3/'],*/

												/*chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],*/

												/*chainName: 'Goerli  Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}




						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							allowance = await contractToken.methods.allowance(account, addressStaking).call();
							console.log("allowance:" + allowance);

							rewardsAmount = await contractStaking.methods.rewardsAmount().call();
							this.setState({ _rewardsAmount: rewardsAmount });
							console.log("rewardsAmount:" + rewardsAmount);

							rewardsRate = await contractStaking.methods.rewardsRate().call();
							this.setState({ _rewardsRate: rewardsRate });
							console.log("rewardsRate:" + rewardsRate);

							countOfOverallStakers = await contractStaking.methods.countOfOverallStakers().call();
							this.setState({ _countOfOverallStakers: countOfOverallStakers });
							console.log("countOfOverallStakers:" + countOfOverallStakers);

							gameIndex = await contractStaking.methods.gameIndex().call();
							this.setState({ _gameIndex: gameIndex });
							console.log("gameIndex:" + gameIndex);

							AquaFrogsCount = await contractStaking.methods.AquaFrogsCount(gameIndex).call();
							this.setState({ _AquaFrogsCount: AquaFrogsCount });
							console.log("AquaFrogsCount:" + AquaFrogsCount);

							FireFrogsCount = await contractStaking.methods.FireFrogsCount(gameIndex).call();
							this.setState({ _FireFrogsCount: FireFrogsCount });
							console.log("FireFrogsCount:" + FireFrogsCount);

							gameStartTime = await contractStaking.methods.gameStartTime().call();
							this.setState({ _gameStartTime: gameStartTime });
							console.log("gameStartTime:" + gameStartTime);

							gameDuration = await contractStaking.methods.gameDuration().call();
							this.setState({ _gameDuration: gameDuration });
							console.log("gameDuration:" + gameDuration);

							stakingEligibilityTime = await contractStaking.methods.stakingEligibilityTime().call();
							this.setState({ _stakingEligibilityTime: stakingEligibilityTime });
							console.log("stakingEligibilityTime:" + stakingEligibilityTime);

							additionalTime = await contractStaking.methods.additionalTime().call();
							this.setState({ _additionalTime: additionalTime });
							console.log("additionalTime:" + additionalTime);

							endTime = gameStartTime + gameDuration + additionalTime;
							this.setState({ _endTime: endTime });


							totalSupplyNFT = await contractNFT.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });

							console.log("Total Supply:" + totalSupplyNFT);


							isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
							this.setState({ _isApproved: isApproved });
							console.log(isApproved);

							publicSale = await contractNFT.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
							console.log("myStakedTokenCount : " + myStakedTokenCount);

							myTokenAmount = await contractToken.methods.balanceOf(account).call();
							this.setState({ _myTokenAmount: myTokenAmount });

							alreadyStakedNFT = await contractStaking.methods.alreadyStakedNFT(account).call();
							if (alreadyStakedNFT) {
								console.log("alreadyStakedNFT : " + alreadyStakedNFT);
								this.setState({ _alreadyStakedNFT: true });
								console.log("_alreadyStakedNFT : " + this.state._alreadyStakedNFT);
							}

							if (allowance > 0) {
								this.setState({ _approved: true });
								console.log("approvedStormsChosen : " + this.state._approved);

							} else {
								this.setState({ _approved: false });
								console.log("approvedStormsChosen : " + this.state._approved);
							}

							let a;
							for (a = 0; a < myStakedTokenCount; a++) {
								getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
								this.setState({ _getMyStakedTokens: getMyStakedTokens });
								console.log("getMyStakedTokens : " + getMyStakedTokens);

								let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
								let timeDuration = Date.now() - (tokenTime * 1000);

								if (timeDuration >= 0) {

									getRewards[a] = (Date.now() - (tokenTime * 1000)) * 10 / 86400000;

								} else {
									getRewards[a] = 0;
								}

								elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(4)];
								this.setState({ _elementArray: elementArray });

								this.setState({ _getRewards: getRewards });
								console.log("tokenTime :" + tokenTime * 1000);
								console.log("tokenTimeDuration :" + timeDuration);
								console.log("getRewards : " + getRewards[a].toFixed(2));

								this.setState({ _getMyStakedTokens: getMyStakedTokens });
								console.log("getMyStakedTokens : " + getMyStakedTokens);
								console.log("elementArray[a] : " + elementArray[a]);
								console.log("elementArray[a][0] : " + elementArray[a][0]);

							}


							for (let q = 0; q < getRewards.length; q++) {
								pendingRewards = pendingRewards * 1 + getRewards[q] * 1;
								this.setState({ _newPendingRewards: pendingRewards * 1 });
								console.log("_newPendingRewards :" + this.state._newPendingRewards);

							}



							for (let y = 0; y < this.state.myNFTWallet; y++) {
								myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
								this.setState({ _myTokenIDs: myTokenIDs })

							}


							console.log("myTokenIDs : " + myTokenIDs[0]);
							console.log("myTokenIDs : " + this.state._myTokenIDs);

							owner = await contractNFT.methods.owner().call();
							console.log("Owner" + owner);


						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}
		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload(true);

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}

	setApprovalForAll = async event => {
		event.preventDefault();

		try {
			await contractNFT.methods.setApprovalForAll(addressStaking, true).send({/*gasLimit: 390000,*/ /* gasLimit: 285000, */gasLimit: 390000, from: account });
			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}

	}

	async stake(staking_id) {


		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + staking_id);

		try {
			isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
			this.setState({ _isApproved: isApproved });
			console.log(isApproved);

			if (isApproved) {
				await contractStaking.methods.stakeNFT(staking_id).send({ /*maxPriorityFeePerGas: 40000000000, gasLimit: 285000,*/ /*gasLimit: 390000,*/gasLimit: 390000, from: account });

			} else {

				await contractNFT.methods.setApprovalForAll(addressStaking, true).send({ /*maxPriorityFeePerGas: 40000000000, gasLimit: 285000,*/ /*gasLimit: 390000,*/gasLimit: 390000, from: account });

			}

			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}


	}

	batchStake = async event => {
		event.preventDefault();

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + myTokenIDs);

		try {
			isApproved = await contractNFT.methods.isApprovedForAll(account, addressStaking).call();
			this.setState({ _isApproved: isApproved });
			console.log(isApproved);

			if (isApproved) {
				await contractStaking.methods.batchStakeNFT(myTokenIDs).send({ /*maxPriorityFeePerGas: 40000000000, gasLimit: 285000,*/ gasLimit: 390000, from: account });

			} else {

				await contractNFT.methods.setApprovalForAll(addressStaking, true).send({ /*maxPriorityFeePerGas: 40000000000, gasLimit: 285000,*/ /*gasLimit: 390000,*/ gasLimit: 390000, from: account });

			}

			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}


	}

	batchUnstake = async event => {
		event.preventDefault();

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + getMyStakedTokens);

		try {

			await contractStaking.methods.batchUnstakeNFT(getMyStakedTokens).send({ /*maxPriorityFeePerGas: 40000000000,/*gasLimit: 285000,*/ gasLimit: 390000, from: account });
			window.location.reload(true);


		} catch (err) {
			console.log(err);
		}

	}

	batchClaimRewards = async event => {
		event.preventDefault();

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + getMyStakedTokens);

		try {

			await contractStaking.methods.batchClaimRewards(getMyStakedTokens).send({ /*maxPriorityFeePerGas: 40000000000,*/ /*gasLimit: 285000,*/ /*gasLimit: 390000,*/ gasLimit: 390000, from: account });
			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}

	}

	async claimRewards(claiming_id) {

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + claiming_id);

		try {

			await contractStaking.methods.claimRewards(claiming_id).send({ /*maxPriorityFeePerGas: 40000000000,*/ /*gasLimit: 390000,*/ /*gasLimit: 285000,*/ gasLimit: 390000, from: account });
			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}


	}

	async unstake(unstaking_id) {

		/*var tokenIDForm = document.getElementById('tokenIDURL').value;*/
		console.log("tokenIDForm:" + unstaking_id);

		try {

			await contractStaking.methods.unstakeNFT(unstaking_id).send({ /*maxPriorityFeePerGas: 40000000000,*/ /*gasLimit: 390000,*/ gasLimit: 390000, from: account });
			window.location.reload(true);


		} catch (err) {
			console.log(err);
		}


	}

	getStakedDetails = async event => {
		event.preventDefault();

		if (this.state.walletAddress != "") {
			getStakedDetailsWindow = 1;
			this.setState({ _getStakedDetailsWindow: getStakedDetailsWindow });
			console.log("getStakedDetailsWindow :" + getStakedDetailsWindow);
		}

		try {

			myStakedTokenCount = await contractStaking.methods.countofMyStakedTokens(account).call();
			console.log("myStakedTokenCount : " + myStakedTokenCount);

			let a;
			for (a = 0; a < myStakedTokenCount; a++) {
				getMyStakedTokens[a] = await contractStaking.methods.stakedTokens(account, a).call();
				//	getRewards = await contractStaking.methods.getRewards(getMyStakedTokens[a]).send({ from: account });

				let tokenTime = await contractStaking.methods.tokenStakedTime(account, getMyStakedTokens[a]).call();
				let timeDuration = Date.now() - (tokenTime * 1000);

				if (timeDuration >= 0) {

					getRewards[a] = ((Date.now() - (tokenTime * 1000)) * 10) / 86400000;

				} else {
					getRewards[a] = 0;
				}

				elementArray[a] = [getMyStakedTokens[a], getRewards[a].toFixed(2)];
				this.setState({ _elementArray: elementArray });

				this.setState({ _getRewards: getRewards });
				console.log("tokenTime :" + tokenTime * 1000);
				console.log("tokenTimeDuration :" + timeDuration);
				console.log("getRewards : " + getRewards[a].toFixed(2));

				this.setState({ _getMyStakedTokens: getMyStakedTokens });
				console.log("getMyStakedTokens : " + getMyStakedTokens);
				console.log("elementArray[a] : " + elementArray[a]);
				console.log("elementArray[a][0] : " + elementArray[a][0]);

			}


			for (let q = 0; q < getRewards.length; q++) {
				pendingRewards = pendingRewards * 1 + getRewards[q] * 1;
				this.setState({ _newPendingRewards: pendingRewards * 1 });
				console.log("_newPendingRewards :" + this.state._newPendingRewards);

			}


		} catch (err) {
			console.log(err);
		}

	}

	getMyOwnNFTDetails = async event => {
		event.preventDefault();

		if (this.state.walletAddress != "") {
			getMyOwnNFTDetailsWindow = 1;
			this.setState({ _getMyOwnNFTDetailsWindow: getMyOwnNFTDetailsWindow });
			console.log("getMyOwnNFTDetailsWindow :" + getMyOwnNFTDetailsWindow);
		}

		try {


			for (let y = 0; y < this.state.myNFTWallet; y++) {
				myTokenIDs[y] = await contractNFT.methods.tokenOfOwnerByIndex(account, y).call();
				this.setState({ _myTokenIDs: myTokenIDs })

			}


		} catch (err) {
			console.log(err);
		}

	}

	closeWindow = async event => {
		event.preventDefault();

		getStakedDetailsWindow = 0;
		this.setState({ _getStakedDetailsWindow: getStakedDetailsWindow });

	}

	closeWindow2 = async event => {
		event.preventDefault();

		getMyOwnNFTDetailsWindow = 0;
		this.setState({ _getMyOwnNFTDetailsWindow: getMyOwnNFTDetailsWindow });

	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	/*moveDemonicInsight = async event => {
		event.preventDefault();

		try {
			
			$(".bg2").animate({
				right: "100%"
			},
	
				1000,
	
				function () {
					$(this).hide();
					//$(this).css("right", "-212px");
				}
			);

		} catch (err) {
			console.log(err);
		}
		window.location.reload(true);

	}*/

	moveDemonicInsight = () => {
		$('.btn1').fadeOut('slow');

		$('.bg2').animate({ marginRight: '-100%' }, "slow", function () {
			//$('.bg2').hide();
			$('.formMain').show('fast').animate({ marginTop: "0" }, "slow");
			$('.formMain2').show('fast');
			$('.battleBtn').show('fast');
			//	$(".bg1").css({ marginLeft: "0" }).show().animate({ marginLeft: "0" }, "slow");
		});
	}

	moveStormsChosen = () => {
		$('.btn2').fadeOut('fast');

		$(".bg1").animate({ marginLeft: "-100%" }, "slow", function () {
			//$(this).hide();

			//$(".bg2").css({ marginLeft: "100%" }).animate({ marginLeft: "0" }, "slow");
		});
		$('.formMain-2').show('fast').animate({ marginTop: "0" }, "slow");
		$('.formMain2-2').show('fast');
		$('.battleBtn-2').show('fast');
	}

	stakeFrog_AquaFrogs = async event => {

		event.preventDefault();
		try {

			await contractStaking.methods.stakeFrog_AquaFrogs(this.state._stakeFrog_AquaFrogs).send({ gasLimit: 385000/*gasLimit: 285000, maxPriorityFeePerGas: "196000000000", maxFeePerGas: "202000000000", */, gasLimit: 390000, from: account });
			this.setState({ _stakeFrog_AquaFrogs: stakeFrog_AquaFrogs });
			console.log("stakeFrog_AquaFrogs : " + this.state._stakeFrog_AquaFrogs);
			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}
	}

	stakeFrog_FireFrogsFunction = async event => {

		event.preventDefault();
		try {
			await contractStaking.methods.stakeFrog_FireFrogs(this.state._stakeFrog_FireFrogsTokens).send({ gasLimit: 385000,/*gasLimit: 285000, maxPriorityFeePerGas: "196000000000", maxFeePerGas: "202000000000", */gasLimit: 390000, from: account });
			this.setState({ _stakeFrog_FireFrogsTokens: stakeFrog_FireFrogs });
			console.log("stakeFrog_FireFrogs : " + this.state._stakeFrog_FireFrogsTokens);
			window.location.reload(true);

		} catch (err) {
			console.log(err);
		}
	}

	approveFunction = async event => {
		event.preventDefault();

		try {

			allowance = await contractToken.methods.allowance(account, addressStaking).call();
			console.log("allowance:" + allowance);

			if (allowance > 0) {

				await contractToken.methods.increaseAllowance(addressStaking, '10000000000000000000000').send({ gasLimit: 385000, from: account });

			} else {

				await contractToken.methods.approve(addressStaking, '10000000000000000000000').send({ gasLimit: 385000, from: account });

			}
			window.location.reload(true);

		} catch (err) {
			this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
			console.log(err);

		}

		this.moveDemonicInsight();

	}

	walletConnect2 = async event => {
		event.preventDefault();

		// Check if MetaMask is installed
	/*	if (window.ethereum) {
			const provider = window.ethereum;

			try {
				// Request access to the user's MetaMask wallet
				await provider.request({ method: "eth_requestAccounts" });

				// Deep link to your website
				window.location.href = "https://metamask.app.link/dapp/battlefrog3.pages.dev/";
				// window.location.href = "https://go.cb-w.com/dapp?cb_url=https:///stake-frogchain.pages.dev/";




			} catch (error) {
				console.error(error);
			}
		} else {
			// MetaMask is not installed, provide a link to download
			window.location.href = "https://metamask.app.link/dapp/battlefrog3.pages.dev/";
		}*/

		 // Check if the user has MetaMask installed
		 if (typeof window.ethereum !== "undefined") {
			// Get the deep link URL
			const deepLinkUrl = "https://metamask.app.link/dapp/battlefrog3.pages.dev/";
		
			// Open the deep link in MetaMask
			window.ethereum.request({ method: "open", url: deepLinkUrl });



			const accounts = await window.ethereum.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contractNFT = new window.ethereum.eth.Contract(ABINFT, addressNFT);
			console.log("contractNFT :" + contractNFT);

			contractToken = new window.ethereum.eth.Contract(ABIToken, addressToken);
			console.log("contractToken :" + contractToken);


			contractStaking = new window.ethereum.eth.Contract(ABIStaking, addressStaking);
			console.log("contractStaking :" + contractStaking);

		  } else {
			// The user does not have MetaMask installed, so redirect them to the MetaMask website
			window.open("https://metamask.io/", "_blank");
		  }
		}
	


	render() {

		return (
			<div class="mainDiv">
				<div class="stakeMain">
					<form onSubmit={this.walletConnect}>
						<button>
							Connect
						</button>
					</form>
					<Element name="up">

						{this.state._navbarOpen < 1 ?
							(

								<div class="miniBarMain">
									<div class="title" onClick={home}><img class="logoUp" src={logoUp} />FROG CHAIN</div>
									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect} class="connect2-2">
												<button class="button-92">
													Connect
												</button>
											</form>) : (<form class="connect2-2" onSubmit={this.walletDisconnect}>
												<button class="button-92">
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

												</button>
											</form>)}

										{this.state.walletAddress === '' ?
											(<form onClick={mm} class="connect2-2-Mob">

												<button class="button-92">Connect</button>

											</form>) : (<form class="connect2-2-Mob" onSubmit={this.walletDisconnect}>
												<button class="button-92">
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

												</button>
											</form>)}

									</div>
									<div class="navbarMainImgSet"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
								</div>) :
							(<div class="miniBarMain">
								<div class="title" onClick={home}><img class="logoUp" src={logoUp} />FROG CHAIN</div>
								<div class="connect2">
									{this.state.walletAddress === '' ?
										(<form onSubmit={this.walletConnect} class="connect2-2">
											<button class="button-92">
												Connect
											</button>
										</form>) : (<form class="connect2-2" onSubmit={this.walletDisconnect}>
											<button class="button-92">
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

											</button>
										</form>)}

									{this.state.walletAddress === '' ?
										(<form onClick={mm} class="connect2-2-Mob">

											<button class="button-92">Connect</button>

										</form>) : (<form class="connect2-2-Mob" onSubmit={this.walletDisconnect}>
											<button class="button-92">
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

											</button>
										</form>)}

								</div>
								<div class="navbarMainImgSet"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
							</div>)}

						<div class="headers">

							<div class="h1">
								<div class="title" onClick={home}><img class="logoUp" src={logoUp} />FROG CHAIN</div>
								<div class="connect">
									<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550} onClick={home}>Home</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={mintSite}>Mint</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={stakeSite}>Staking</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={() => window.location.href = 'battlefield'}>Battlefield</Link></div>
								</div>

								<div class="right">

									<div class="icons">
										<img onClick={opensea} src={os} />
										<img onClick={tweet} src={twitter} />
										<img onClick={tg} src={telegram} />
									</div>
									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect} class="connect2-2">
												<button class="button-92">
													Connect
												</button>
											</form>) : (<form class="connect2-2" onSubmit={this.walletDisconnect}>
												<button class="button-92">
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

												</button>
											</form>)}

										{this.state.walletAddress === '' ?
											(<form onClick={mm} class="connect2-2-Mob">

												<button class="button-92">Connect</button>

											</form>) : (<form class="connect2-2-Mob" onSubmit={this.walletDisconnect}>
												<button class="button-92">
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

												</button>
											</form>)}

									</div>

								</div>


								<div class="connectMob">
									<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
									<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
								</div>



							</div>
						</div>

						{this.state._navbarOpen > 0 ?
							(<div class="littleNav" data-aos="fade-left">

								<div ><Link activeClass="" id="fontSize2" to="story" spy={true} smooth={true} duration={550} onClick={home}>Home</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={mintSite}>Mint</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={stakeSite}>Staking</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={() => window.location.href = 'battlefield'}>Battlefield</Link></div>

								<div class="icons">
									<img onClick={opensea} src={os} />
									<img onClick={tweet} src={twitter} />
									<img onClick={tg} src={telegram} />
								</div>
							</div>) : null}
					</Element>

					<div class="navBarStake">
						<div class="bg1">
							<button onClick={this.moveDemonicInsight} class="btn1">AQUA FROGS</button>

							<div class="formMain">
								{this.state.myNFTWallet > 0 || this.state._alreadyStakedNFT ?

									(<div>
										{this.state._alreadyStakedNFT ?
											(
												<div class="alreadyMain">
													<div class="already">
														Already Staked
													</div>
													<button class="stake" onClick={this.batchUnstake}>Unstake</button>
												</div>) :
											(<div>
												<div class="tS">Stake 1 NFT to join the game</div>
												<div class="tokenIds" >

													{this.state._myTokenIDs.map((element) => {


														var ID = element;

														let url = secretGif;

														//url = "https://dogeum.com/images/" + ID + ".jpg";
														console.log("url :" + url);

														return (
															<div class="tokenIds2">
																<div class="flip-card">
																	<div class="flip-card-inner">
																		<div class="flip-card-front">

																			<img class="images_show" src={url} /></div>
																		<div class="flip-card-back">
																			<div class="elementName">{element}</div>

																			{this.state._isApproved ?
																				(<button class="stake" onClick={() => this.stake(element)}>Stake</button>) :
																				(<button class="stake" onClick={() => this.stake(element)}>Approve</button>)
																			}


																		</div>
																	</div>
																</div></div>);



													})}


												</div></div>)}

									</div>
									) :
									(<div class="notification"><div class="notification1"><img class="bell" src={bell} />You Don't have any NFTs in your Wallet</div>
										<div onClick={mintSite} class="buy">Buy NFTs</div></div>)}
							</div>

							{this.state._approved == true ?

								(<div class="formMain2">
									{this.state._alreadyStakedNFT ?
										(
											<form onSubmit={this.stakeFrog_AquaFrogs}>
												<div class="relicT">$FROG Amount: {(this.state._myTokenAmount / 1000000000).toFixed(0)}$FROG</div>
												<div><input placeholder="0" type="text" name="_stakeFrog_AquaFrogs" value={Number(this.state._stakeFrog_AquaFrogs)}
													onChange={event => this.setState({ _stakeFrog_AquaFrogs: event.target.value })} /></div>
												<button class="inputSection" type='submit'>Stake</button>
											</form>) : null}
								</div>) :

								(<div class="formMain2">
									<form onSubmit={this.stakeFrog_AquaFrogs}>
										<div class="relicT">$FROG Amount: {(this.state._myTokenAmount / 1000000000).toFixed(0)} $FROG</div>
										<button onClick={this.approveFunction} class="inputSection" type='submit'>Approve</button>
									</form>
								</div>)}

							<button class="battleBtn" onClick={() => window.location.href = '/battlefield'}>BATTLE ARENA<img class="battle" src={battle} /></button>

						</div>

						<div class="bg2">
							<button onClick={this.moveStormsChosen} class="btn2">FIRE FROGS</button>

							<div class="formMain-2">
								{this.state.myNFTWallet > 0 || this.state._alreadyStakedNFT ?

									(<div>
										{this.state._alreadyStakedNFT ?
											(
												<div class="alreadyMain">
													<div class="already">
														Already Staked
													</div>
													<button class="stake" onClick={this.batchUnstake}>Unstake</button>
												</div>) :
											(
												<div>
													<div class="tS">Stake 1 NFT to join the game</div>

													<div class="tokenIds" >

														{this.state._myTokenIDs.map((element) => {


															var ID = element;

															let url = secretGif;

															//url = "https://dogeum.com/images/" + ID + ".jpg";
															console.log("url :" + url);

															return (
																<div class="tokenIds2">
																	<div class="flip-card">
																		<div class="flip-card-inner">
																			<div class="flip-card-front">

																				<img class="images_show" src={url} /></div>
																			<div class="flip-card-back">
																				<div class="elementName">{element}</div>

																				{this.state._isApproved ?
																					(<button class="stake" onClick={() => this.stake(element)}>Stake</button>) :
																					(<button class="stake" onClick={() => this.stake(element)}>Approve</button>)
																				}


																			</div>
																		</div>
																	</div></div>);



														})}

													</div>
												</div>
											)}</div>) :
									(<div class="notification"><div class="notification1"><img class="bell" src={bell} />You Don't have any NFTs in your Wallet</div>
										<div onClick={mintSite} class="buy">Buy NFTs</div></div>)}

							</div>
							{this.state._approved == true ?

								(<div class="formMain2-2">
									{this.state._alreadyStakedNFT ?
										(
											<form onSubmit={this.stakeFrog_FireFrogsFunction}>
												<div class="relicT">$FROG Amount: {(this.state._myTokenAmount / 1000000000).toFixed(0)} $FROG</div>
												<div><input placeholder="0" type="text" name="_stakeFrog_FireFrogsTokens" value={this.state._stakeFrog_FireFrogsTokens}
													onChange={event => this.setState({ _stakeFrog_FireFrogsTokens: event.target.value })} /></div>
												<button class="inputSection" type='submit'>Stake</button>
											</form>) : null}
								</div>) :

								(<div class="formMain2-2">
									<form>
										<div class="relicT">$FROG Amount: {(this.state._myTokenAmount / 1000000000).toFixed(0)} $FROG</div>
										<button onClick={this.approveFunction} class="inputSection" type='submit'>Approve</button>
									</form>
								</div>)}

							<button class="battleBtn-2" onClick={() => window.location.href = '/battlefield'}>BATTLE ARENA<img class="battle" src={battle} /></button>

						</div>
					</div>

				</div>


			</div>)
	}
}

export default Stake;