import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/home";
import Game from "./Pages/game";

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='battlefield' element={<Game />} />

				</Routes>
			</BrowserRouter>
			<div>
			</div>
		</div>
	)
}


export default App;
